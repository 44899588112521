import '../components/styles/style.scss';

import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Route } from 'react-router-dom';

import AccessDenied from '../components/AccessDenied/AccessDenied';
import App from '../components/app/App';
import * as serviceWorker from '../serviceWorker';

const routing = (
    <BrowserRouter>
        <div>
            <Route path="/" component={App} />
            <Route path="/accessdenied" component={AccessDenied} />
        </div>
    </BrowserRouter>
)

ReactDOM.render(routing, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
