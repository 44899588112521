import * as React from 'react';

interface IMessageComponentProps {
    isShowingClass: string;
    message: string;
    header: string;
    _onClick: any;
    isFailed: boolean;
}

interface IMessageComponentState { }

export default class MessageComponent extends React.Component<IMessageComponentProps, IMessageComponentState> {
    constructor(props: IMessageComponentProps) {
        super(props);

        this.state = {
        }
    }

    public render() {
        return (
            <div className={this.props.isShowingClass}>
                {this.renderMessage()}
            </div>
        );
    }

    private renderMessage = () => {
        if (!this.props.isFailed) {
            return (
                <article className="message">
                    <div className="message-header">{this.props.header}</div>
                    <div className="message-body" >{this.props.message}</div>
                    <button aria-label="delete" className="delete" onClick={this.props._onClick}></button>
                </article>
            )
        }
        else {
            return (
                <article className="message failed">
                    <div className="message-header failed">{this.props.header}</div>
                    <div className="message-body failed">{this.props.message}</div>
                    <button aria-label="delete" className="delete" onClick={this.props._onClick}></button>
                </article>
            );
        }
    }
}
