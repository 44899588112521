import { IPersonaProps } from 'office-ui-fabric-react';

export function IsNullOrWhiteSpace(str: string) {
    return (typeof str === 'undefined' || str == null)
        || str.replace(/\s/g, '').length < 1;
};

export function convertToText(array: IPersonaProps[]) {
    const arr: string[] = []
    array.map(people => {
        arr.push(`${people.text} (${people.secondaryText})`);
    })
    return arr;
}

export function getAreaPath() {
    switch (process.env.REACT_APP_ENV.toLowerCase()) {
        case 'local':
            return process.env.REACT_APP_DEV_AREAPATH_BASE;
        case 'dev':
            return process.env.REACT_APP_DEV_AREAPATH_BASE;
        case 'uat':
            return process.env.REACT_APP_UAT_AREAPATH_BASE;
        case 'prod':
            return process.env.REACT_APP_PROD_AREAPATH_BASE;
    }
}
