import { Dropdown } from 'office-ui-fabric-react';
import * as React from 'react';

interface IDropdownProps {
    dropDownOptions: Array<string>;
    isMandatory: boolean;
    dropDownlabel: string;
    _onChange: any;
    isDisabled?: boolean
}

interface IDropDownState {
    displayOptions: Array<any>;
}

export default class DropDownComponent extends React.Component<IDropdownProps, IDropDownState> {
    constructor(props: IDropdownProps) {
        super(props);
        this.state = {
            displayOptions: []
        }
        this.populateDropDown();
    }

    public render() {
        return (
            <div>
                <Dropdown
                    disabled={this.props.isDisabled}
                    label={this.props.dropDownlabel}
                    onChange={this.props._onChange}
                    options={this.state.displayOptions}
                    required={this.props.isMandatory ? true : false}
                />
            </div>
        );
    }

    private populateDropDown = () => {
        this.props.dropDownOptions.map((data: string, index: number) => {
            this.state.displayOptions.push({ key: index, text: data })
        })
    };
}
