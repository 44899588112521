import { AdalConfig, adalFetch, AuthenticationContext, withAdalLogin } from 'react-adal';

export const adalConfig: AdalConfig = {
    tenant: process.env.REACT_APP_TENANT_ID,
    clientId: getClientId(),
    redirectUri: window.location.origin + '/',
    postLogoutRedirectUri: window.location.origin + '/',
    cacheLocation: 'localStorage' as 'localStorage',
    instance: 'https://login.microsoftonline.com/',
    endpoints: {
        api: getClientId(),
        graph: 'https://graph.microsoft.com'
    },

}

export const authContext = new AuthenticationContext(adalConfig)

export const apiFetch = (url, options) => {
    return adalFetch(authContext, adalConfig.endpoints.api, fetch, url, options);
};

export const adalGraphFetch = (fetch: any, url?: any, options?: any) =>
    adalFetch(authContext, adalConfig.endpoints.graph, fetch, url, options)

export const withAdalLoginApi = withAdalLogin(
    authContext,
    adalConfig.endpoints.api
)

function getClientId() {
    switch (process.env.REACT_APP_ENV.toLowerCase()) {
        case 'local':
            return process.env.REACT_APP_DEV_CLIENT_ID;
        case 'dev':
            return process.env.REACT_APP_DEV_CLIENT_ID;
        case 'uat':
            return process.env.REACT_APP_UAT_CLIENT_ID;
        case 'prod':
            return process.env.REACT_APP_PROD_CLIENT_ID;
    }
}