import { OdinClient } from 'odinclient';
import { Checkbox, Dropdown, IDropdownOption, IPersonaProps, PrimaryButton, TextField } from 'office-ui-fabric-react';
import * as React from 'react';

import { RequestType } from '../../models/Constants/enums';
import { authContext } from '../../utils/adalConfig';
import { ApiWrapper } from '../../utils/ApiWrapper';
import { AppInsightsService } from '../../utils/appInsights';
import { convertToText, getAreaPath, IsNullOrWhiteSpace } from '../../utils/helperFunctions';
import DropDownComponent from '../DropDownComponent';
import DropZoneComponent from '../DropZoneComponent';
import { SearchPersonaComponent } from '../SearchPersonaComponent';

const api = new ApiWrapper();
const appInsights = new AppInsightsService();
const odinClient = new OdinClient(authContext.config.clientId, process.env.REACT_APP_ENV);

interface IRequestProps {
    messageTrigger: any;
    isLoading: any;
}

interface IRequestState {
    classificationOptions: Array<string>;
    classificationSelected: string;
    requirementSubclassificationOptions: Array<IDropdownOption>;
    specificationSubclassificationOptions: Array<string>;
    subClassificationSelected: string;
    title: string;
    projectName: string;
    description: string;
    files: File[];
    currentAreaPath: string;
    currentlySelectedPeople: IPersonaProps[];
    docNumberOnlyChecked: boolean;
    isWarning: boolean;
}

const initState: IRequestState = {
    classificationOptions: ["Requirement", "Specification"],
    classificationSelected: undefined,
    requirementSubclassificationOptions: [{ key: "Design", text: "Design" }, { key: "System", text: "System" }],
    specificationSubclassificationOptions: ["Design", "System", "Subsystem", "Platform", "Industry Regulatory", "Business and Marketing", "Quality, Test and Reliability", "MTE"],
    subClassificationSelected: undefined,
    title: undefined,
    projectName: undefined,
    description: undefined,
    files: [],
    currentAreaPath: getAreaPath(),
    currentlySelectedPeople: [],
    docNumberOnlyChecked: false,
    isWarning: false

}

export default class DocumentPageComponent extends React.Component<IRequestProps, IRequestState> {
    constructor(props: IRequestProps) {
        super(props);
        this.state = initState;
    }

    public render() {
        return (
            <div style={{ marginTop: 8 }} id='DocumentRequestPage' >
                <form onSubmit={this.handleSubmit}>
                    <Checkbox label='Doc Number Needed Only' checked={this.state.docNumberOnlyChecked} onChange={this.handleDocNumberChange} />
                    <TextField label='Title' onChange={this.handleTitleChange} required />
                    <TextField label='Requestor' iconProps={{ iconName: 'ProfileSearch' }} value={localStorage.getItem('userContext') ? JSON.parse(atob(localStorage.getItem('userContext'))).displayName : 'Loading...'} disabled required />
                    <DropDownComponent isDisabled={this.state.docNumberOnlyChecked} dropDownlabel='Classification' dropDownOptions={this.state.classificationOptions} isMandatory={true} _onChange={this.handleClassificationChange} />
                    {this.classificationChecker()}
                    <TextField disabled={this.state.docNumberOnlyChecked} label='Project Name' onChange={this.handleProjectNameChange} required />
                    <SearchPersonaComponent isDisabled={this.state.docNumberOnlyChecked} label={'ECO reviewers'} _onChange={this.handleEcoReviewersChange} isWarning={this.state.isWarning} currentlySelectedItems={this.state.currentlySelectedPeople} />
                    <TextField label='Additional Information' onChange={this.handleDescriptionChange} multiline autoAdjustHeight />
                    {
                        !this.state.docNumberOnlyChecked && <DropZoneComponent _handleOnDrop={this.handleOnDrop} acceptedFiles={this.state.files} />
                    }
                    <PrimaryButton type='submit' text='Submit Request' disabled={!this.isFormValid()} />
                </form>
            </div>
        );
    }

    private classificationChecker = () => {
        switch (this.state.classificationSelected) {
            case 'Requirement':
                return <Dropdown disabled={this.state.docNumberOnlyChecked} label='Sub Classification' onChange={this.handleSubClassificationChange} options={this.state.requirementSubclassificationOptions} required={true} />
            case 'Specification':
                return <DropDownComponent isDisabled={this.state.docNumberOnlyChecked} dropDownlabel='Sub Classification' isMandatory={true} _onChange={this.handleSubClassificationChange} dropDownOptions={this.state.specificationSubclassificationOptions} />
            default:
                return ''
        }
    }

    private isFormValid = () => {
        if (this.state.docNumberOnlyChecked) {
            return (!IsNullOrWhiteSpace(this.state.title) && !IsNullOrWhiteSpace(localStorage.getItem('userContext')))
        }
        else {
            return (!IsNullOrWhiteSpace(this.state.title) && !IsNullOrWhiteSpace(localStorage.getItem('userContext'))
                && !IsNullOrWhiteSpace(this.state.projectName) && this.state.classificationSelected !== undefined
                && this.state.subClassificationSelected !== undefined
                && this.state.currentlySelectedPeople.length !== 0)
        }
    }

    private handleEcoReviewersChange = (input: IPersonaProps[]) => {
        //check if selected user is already in the array
        if (!JSON.stringify(this.state.currentlySelectedPeople).includes(JSON.stringify(input[input.length - 1]))) {
            this.setState({
                currentlySelectedPeople: input,
                isWarning: false
            })
        } else if (this.state.currentlySelectedPeople.length > input.length) {
            //if we are deleting user
            this.setState({
                currentlySelectedPeople: input,
                isWarning: false
            })
        }
        else {
            input = this.state.currentlySelectedPeople;
            this.setState({ isWarning: true })
        }
    };

    private handleDocNumberChange = (event?: React.FormEvent<HTMLElement | HTMLInputElement>, checked?: boolean) => {
        this.setState({ docNumberOnlyChecked: !this.state.docNumberOnlyChecked })
    }

    private handleClassificationChange = (event: React.FormEvent<HTMLDivElement>, selectedItem: IDropdownOption) => {
        this.setState({ classificationSelected: selectedItem.text })
    }

    private handleSubClassificationChange = (event: React.FormEvent<HTMLDivElement>, selectedItem: IDropdownOption) => {
        this.setState({ subClassificationSelected: selectedItem.text })
    }

    private handleTitleChange = (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, TextFieldValue?: string) => {
        this.setState({ title: TextFieldValue })
    }

    private handleProjectNameChange = (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, TextFieldValue?: string) => {
        this.setState({ projectName: TextFieldValue })
    }

    private handleDescriptionChange = (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, TextFieldValue?: string) => {
        this.setState({ description: TextFieldValue })
    }

    private handleOnDrop = (acceptedFiles: File[], rejectedFiles: File[]) => {
        this.setState({ files: this.state.files.concat(acceptedFiles) });

        if (rejectedFiles.length > 0) {
            alert('File should be less than 100MB')
        }
    }

    private handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        this.props.isLoading(true);

        odinClient.createWorkItem(undefined, 'Request', false, false,'', '2.0', this.getWorkitemFields()).then(_workItemId => {

            if (this.state.files.length > 0) {
                this.postAttachments(_workItemId);
            }
            else {
                this.props.isLoading(false);
                this.props.messageTrigger(_workItemId, false)
                appInsights.trackEvent('Work item created', RequestType.Document)
            }
        }).catch(error => {
            appInsights.logException(error)
            this.props.isLoading(false);
            this.props.messageTrigger(`${JSON.stringify(error.message)}`, true)
        })

        event.preventDefault();
    }

    private postAttachments = (_workItemId) => {
        api.uploadAttachmentsToAzureDevOps(this.state.files, '2.0').then(result => {
            let Attachments = [];
            result.map((AttachmentDto) => {
                const AttachmentData = {
                    id: AttachmentDto.id,
                    type: 'AttachedFile',
                    url: AttachmentDto.url,
                    comment: "Attached via CE Intake",
                    name: AttachmentDto.fileName,
                    workItemId: _workItemId
                }
                Attachments.push(AttachmentData);
            })

            odinClient.addLinksToWorkItem( false, '' , '2.0', Attachments).then(result => {
                this.props.isLoading(false);
                this.props.messageTrigger(_workItemId, false)
                appInsights.trackEvent('Work item created', RequestType.Document, Attachments.length)
            })
        });
    }

    private getWorkitemFields() {
        return {
            'Microsoft.Custom.Surface.RequestorType': RequestType.Document,
            'Microsoft.Custom.DocNumber': this.state.docNumberOnlyChecked ? 'Yes' : 'No',
            'System.AreaPath': this.state.currentAreaPath === 'DevicesDev' ? this.state.currentAreaPath : `${this.state.currentAreaPath}\\DocTree`,
            'System.Title': this.state.title,
            'System.Description': this.state.description,
            'System.AssignedTo': 'pending@microsoft.com',
            'Microsoft.Custom.Surface.ProjectName': this.state.projectName,
            'Custom.CEECOReviewer': convertToText(this.state.currentlySelectedPeople),
            'Custom.CEClassification': this.state.classificationSelected,
            'Custom.CESubClassification': this.state.subClassificationSelected,
            'Custom.Requester': JSON.parse(atob(localStorage.getItem('userContext'))).userPrincipalName
        }
    }
}