import { OdinClient } from 'odinclient';
import { IPersonaProps, PrimaryButton, TextField } from 'office-ui-fabric-react';
import * as React from 'react';

import { RequestType } from '../../models/Constants/enums';
import { authContext } from '../../utils/adalConfig';
import { AppInsightsService } from '../../utils/appInsights';
import { convertToText, getAreaPath, IsNullOrWhiteSpace } from '../../utils/helperFunctions';
import { SearchPersonaComponent } from '../SearchPersonaComponent';

const appInsights = new AppInsightsService();
const odinClient = new OdinClient(authContext.config.clientId, process.env.REACT_APP_ENV);

interface IRequestProps {
    messageTrigger: any;
    isLoading: any;
}

interface IRequestState {
    projectName: string;
    description: string;
    currentAreaPath: string;
    currentlySelectedPeople: IPersonaProps[];
    isWarning: boolean;
}

const initState: IRequestState = {
    projectName: undefined,
    description: undefined,
    currentAreaPath: getAreaPath(),
    currentlySelectedPeople: [],
    isWarning: false
}

export default class OnePdmPageComponent extends React.Component<IRequestProps, IRequestState> {
    constructor(props: IRequestProps) {
        super(props);
        this.state = initState;
    }

    public render() {
        return (
            <div id='OnePdmRequestPage' >
                <form onSubmit={this.handleSubmit}>
                    <TextField label='Requestor' iconProps={{ iconName: 'ProfileSearch' }} value={localStorage.getItem('userContext') ? JSON.parse(atob(localStorage.getItem('userContext'))).displayName : 'Loading...'} disabled required />
                    <TextField label='Project Name' onChange={this.handleProjectNameChange} required />
                    <SearchPersonaComponent label={'Team members requesting access'} _onChange={this.handleEcoReviewersChange} isWarning={this.state.isWarning} currentlySelectedItems={this.state.currentlySelectedPeople} />
                    <TextField label='Additional Information' onChange={this.handleDescriptionChange} multiline autoAdjustHeight />
                    <PrimaryButton type='submit' text='Submit Request' disabled={!this.isFormValid()} />
                </form>
            </div>
        );
    }

    private isFormValid = () => {
        return (!IsNullOrWhiteSpace(localStorage.getItem('userContext')) && !IsNullOrWhiteSpace(this.state.projectName)
            && this.state.currentlySelectedPeople.length !== 0)
    }

    private handleEcoReviewersChange = (input: IPersonaProps[]) => {
        //check if selected user is already in the array
        if (!JSON.stringify(this.state.currentlySelectedPeople).includes(JSON.stringify(input[input.length - 1]))) {
            this.setState({
                currentlySelectedPeople: input,
                isWarning: false
            })
        } else if (this.state.currentlySelectedPeople.length > input.length) {
            //if we are deleting user
            this.setState({
                currentlySelectedPeople: input,
                isWarning: false
            })
        }
        else {
            input = this.state.currentlySelectedPeople;
            this.setState({ isWarning: true })
        }
    };

    private handleProjectNameChange = (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, TextFieldValue?: string) => {
        this.setState({ projectName: TextFieldValue })
    }

    private handleDescriptionChange = (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, TextFieldValue?: string) => {
        this.setState({ description: TextFieldValue })
    }

    private handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        this.props.isLoading(true);
        odinClient.createWorkItem(undefined, 'Request', false, false, '', '2.0', this.getWorkitemFields()).then(_workItemId => {
            this.props.isLoading(false);
            this.props.messageTrigger(_workItemId)
            appInsights.trackEvent('Work item created', RequestType.OnePdmProjectAccess)
        }).catch(error => {
            appInsights.logException(error)
            this.props.isLoading(false);
            this.props.messageTrigger(`${JSON.stringify(error.message)}`, true)
        });
        event.preventDefault();
    }

    private getWorkitemFields() {
        return {
            'Microsoft.Custom.Surface.RequestorType': RequestType.OnePdmProjectAccess,
            'System.AreaPath': this.state.currentAreaPath === 'DevicesDev' ? this.state.currentAreaPath : `${this.state.currentAreaPath}\\Access`,
            'System.Title': `OnePDM Project Access + ${this.state.projectName}`,
            'System.Description': `${this.state.description} <br/><br/><strong style="color:red">- Team members requesting access -</strong><br/> ${convertToText(this.state.currentlySelectedPeople)}`,
            'System.AssignedTo': 'pending@microsoft.com',
            'Microsoft.Custom.Surface.ProjectName': this.state.projectName,
            'Custom.Requester': JSON.parse(atob(localStorage.getItem('userContext'))).userPrincipalName
        }
    }
}