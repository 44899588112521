import { IDropdownOption, Spinner } from 'office-ui-fabric-react';
import * as React from 'react';

import DocumentPageComponent from '../CustomComponents/CustomPages/DocumentComponent';
import OnePdmPageComponent from '../CustomComponents/CustomPages/OnePdmComponent';
import DropDownComponent from '../CustomComponents/DropDownComponent';
import MessageComponent from '../CustomComponents/MessageComponent';
import { RequestType } from '../models/Constants/enums';
import { AppInsightsService } from '../utils/appInsights';

const appInsights = new AppInsightsService();

interface IRequestProps { }

interface IRequestState {
    requestTypeOptions: Array<string>;
    requestTypeSelected: string;
    showMessage: boolean;
    isLoading: boolean
    isFailed: boolean
    workItemId: string;
}

const spinnerStyles = {
    circle: {
        height: 150,
        width: 150,
        borderWidth: 10,
    }
}

const initState: IRequestState = {
    requestTypeOptions: Object.values(RequestType),
    requestTypeSelected: undefined,
    showMessage: false,
    isLoading: false,
    isFailed: false,
    workItemId: undefined
}

export default class CreateRequest extends React.Component<IRequestProps, IRequestState> {
    constructor(props: IRequestProps) {
        super(props);
        this.state = initState;
        appInsights.trackPageView();
    }

    public render() {
        return (
            <div id='CreateRequestPage' >
                   <h2>This tool is retired, the new direction is to author / modify / revise the documents / parts you need directly in OnePDM without having to go through the Intake process.</h2>
                   <br/>For Spec Document creation in OnePDM please see the training material for OnePDM here: <br/><a href="http://aka.ms/onepdm">http://aka.ms/onepdm</a> or <a href="mailto:OnePDMSupport@microsoft.com">OnePDMSupport@microsoft.com</a>
                    <br/>If you have technical issues with OnePDM, please reach out to &quot;OnePDM Support&quot; <a href="mailto:OnePDMSupport@microsoft.com">OnePDMSupport@microsoft.com</a>
                    <br/><br/>If you have specific questions about the training content, please reach out to: <br/><a href="mailto:1pdmtrain@microsoft.com">1pdmtrain@microsoft.com</a>
                    <br/><br/>Templates and process documentation are saved in OnePDM: <br/>Doc# M1135943 (link below) <br/><a href="https://onepdm.plm.microsoft.com/onepdm/?StartItem=Document%3A10CCF6430A014615895BD22398DB6F27%3Areleased">https://onepdm.plm.microsoft.com/onepdm/?StartItem=Document%3A10CCF6430A014615895BD22398DB6F27%3Areleased</a>
                    <br/><br/> For instructions/issues around  Spec Doc/DocTree/process how-to's… please reach out to: <br/>Devices Spec Management Help <a href="mailto:spechelp@microsoft.com">spechelp@microsoft.com</a>
                    <br/><br/> For all other Documents outside of the Spec Doc process, nothing there has changed, and you can request through DCM team:<br/> <a href="https://aka.ms/DCM-Work-Request">https://aka.ms/DCM-Work-Request</a> 
            </div>
        );
    }

    private renderMessage = () => {
        if (!this.state.isFailed) {
            return (
                <MessageComponent isFailed={false} _onClick={this.handleClick} isShowingClass={this.state.showMessage ? 'show' : 'hide'} message={`Request #${this.state.workItemId} was successfully created`} header={'Success'}>
                </MessageComponent>
            );
        }
        else {
            return (
                <MessageComponent isFailed={true} _onClick={this.handleClick} isShowingClass={this.state.showMessage ? 'show' : 'hide'} message={`${this.state.workItemId}`} header={'Failed'}>
                </MessageComponent>
            );
        }
    }
    private renderPage = () => {
        switch (this.state.requestTypeSelected) {
            case 'Document':
                return <DocumentPageComponent messageTrigger={this.handlerMessage} isLoading={this.handlerLoading} />
            case 'OnePDM Project Access':
                return <OnePdmPageComponent messageTrigger={this.handlerMessage} isLoading={this.handlerLoading} />
            default:
                return <DocumentPageComponent messageTrigger={this.handlerMessage} isLoading={this.handlerLoading} />
        }
    }

    private handlerMessage = (response: string, isFailed: boolean) => {

        if (isFailed) {
            this.setState({
                showMessage: true,
                workItemId: response,
                isFailed: true
            })
        } else {
            this.setState({
                showMessage: true,
                workItemId: response,
                isFailed: false

            })
        }
    }

    private handlerLoading = (_isLoading: boolean) => {
        this.setState({
            isLoading: _isLoading
        })
    }

    private handleClick = (event: any) => {
        this.setState({ showMessage: false })
        event.preventDefault();
    }

    private handleTypeChange = (event: React.FormEvent<HTMLDivElement>, selectedItem: IDropdownOption) => {
        this.setState({ requestTypeSelected: selectedItem.text })
    }

}
