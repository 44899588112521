import 'office-ui-fabric-core/dist/css/fabric.css';

import { History, LocationState } from 'history';
import {
  IButtonProps,
  Icon,
  Image,
  initializeIcons,
  Nav,
  Persona,
  PersonaPresence,
  PersonaSize,
  Spinner,
  Text,
} from 'office-ui-fabric-react';
import React from 'react';
import { BrowserRouter, Link, Route } from 'react-router-dom';

import msftLogo from '../../static/msftLogo.png';
import AccessDenied from '../AccessDenied/AccessDenied';
import CreateRequest from '../CreateRequest/CreateRequest';
import { EMPTY_USER, IUser } from '../models/IUser';
import { ApiWrapper } from '../utils/ApiWrapper';
import { AppInsightsService } from '../utils/appInsights';

initializeIcons();
const api = new ApiWrapper();
const appInsights = new AppInsightsService();

interface IGetPersonaProps {
  history: History<LocationState>;
}

interface IGetPersonaState {
  currentUser: IUser;
  isLoading: boolean;
  currentUserPhoto: any;
  hasAccess: boolean;
}

const spinnerStyles = {
  circle: {
    height: 150,
    width: 150,
    borderWidth: 10,
  }
}

class App extends React.Component<IGetPersonaProps, IGetPersonaState> {
  constructor(props: IGetPersonaProps) {
    super(props);
    this.state = {
      currentUser: EMPTY_USER,
      isLoading: false,
      currentUserPhoto: null,
      hasAccess: true
    }
  }

  public componentDidMount() {
    if (!localStorage.getItem('userContext')) {
      this._getCurrentUser();
    }
  }

  public render() {
    return (
      <BrowserRouter>
        <div className="ms-Grid" dir="ltr">
          <div className="ms-Grid-row">
            <div className='header'>
              <Image height={30} src={msftLogo} className='msftLogo' alt="Microsoft Logo" />
              <div className='headerDivider' />
              <Text className='headerTitle'>CE Intake</Text>
              <div className='container'>
                <div className='navItemsRight'>
                  <Persona id="loginName" text={(localStorage.getItem('userContext') && !this.state.isLoading) ? JSON.parse(atob(localStorage.getItem('userContext'))).displayName : this.state.currentUser.displayName} size={PersonaSize.size40} presence={PersonaPresence.none} imageUrl={this.state.currentUserPhoto} />
                  <a title="Data Protection Notice" href="https://aka.ms/msdpn" target="_blank">
                    <i className="ms-Icon ms-Icon--LaptopSecure" aria-hidden="true"></i>
                  </a>
                  <i className="ms-Icon ms-Icon--Leave" aria-hidden="true" onClick={api.logout}></i>
                </div>
              </div>
            </div>

            {this.state.hasAccess ? this.userAuthorized() : <AccessDenied />}

            <div className={(this.state.isLoading ? 'show' : 'hide')} id='loadingScreen'>
              <Spinner styles={spinnerStyles} />
              <label aria-label='Submitting Request'>Loading...</label>
            </div>
          </div>
        </div>
      </BrowserRouter>
    );
  };

  private userAuthorized = () => {
    return (
      <>
        <div className={'ms-Grid-col ms-sm12 ms-lg4 ms-xl2'}>
          <Nav
            className='leftnav'
            linkAs={this.onRenderLink}
            expandButtonAriaLabel="Expand or collapse"
            groups={[
              {
                links: [
                  {
                    name: 'Create Request',
                    url: '/',
                    key: 'Create Request'
                  }
                ]
              }
            ]}
          />
        </div>
        <div className={(this.state.isLoading ? 'hide' : 'show')} >
          <div className="ms-Grid-col ms-sm12 ms-lg8 msxl-10">
            <Route path="/" exact component={CreateRequest} />
          </div>
        </div>
      </>
    )
  }

  // custom component to make react-router-dom Link component work in fabric Nav
  private onRenderLink = (props: IButtonProps) => {
    return (
      <Link
        className={props.className}
        style={{ color: 'inherit', boxSizing: 'border-box' }}
        to={props.href}
      >
        <span style={{ display: 'flex' }}>
          {!!props.iconProps && (
            <Icon style={{ margin: '0 4px' }} {...props.iconProps} />
          )}
          {props.children}
        </span>
      </Link>
    );
  };

  private _getCurrentUser = () => {
    this.setState(
      {
        isLoading: true
      })
    api.getLoggedInUserOdin().then((user: IUser) => {
      const userContext = btoa(JSON.stringify(user));
      localStorage.setItem('userContext', userContext);

      this.setState(
        {
          currentUser: user,
          hasAccess: true,
          isLoading: false
        }
      );

    }).catch(error => {
      appInsights.logException(error);
      this.setState(
        {
          hasAccess: false,
          isLoading: false
        }
      );
    });
  }
}

export default App;
