import { Label, TooltipHost } from 'office-ui-fabric-react';
import { IPersonaProps } from 'office-ui-fabric-react/lib/Persona';
import { IBasePickerSuggestionsProps, NormalPeoplePicker } from 'office-ui-fabric-react/lib/Pickers';
import * as React from 'react';

import { IUser } from '../models/IUser';
import { ApiWrapper } from '../utils/ApiWrapper';

const api = new ApiWrapper();

interface IPeoplePickerExampleState {
    currentPicker?: number;
    isPickerDisabled?: boolean;
}

interface IPeoplePickerProps {
    label: string,
    _onChange: any,
    isWarning: boolean,
    currentlySelectedItems: IPersonaProps[],
    isDisabled?: boolean
}

const suggestionProps: IBasePickerSuggestionsProps = {
    suggestionsHeaderText: 'Found Users',
    noResultsFoundText: 'No results found',
    loadingText: 'Searching...'
};

export class SearchPersonaComponent extends React.Component<IPeoplePickerProps, IPeoplePickerExampleState> {

    constructor(props: IPeoplePickerProps) {
        super(props);

        this.state = {
            currentPicker: 100,
            isPickerDisabled: false,
        };
    }

    public render() {
        return (
            <React.Fragment>
                <Label disabled={this.props.isDisabled} required={true} >{this.props.label}<span className={(this.props.isWarning ? 'show' : 'hide')} id="warningText"> - User already added -</span></Label >
                <TooltipHost
                    content="Enter name or email to search"
                    calloutProps={{ gapSpace: 0 }}
                >
                    <NormalPeoplePicker
                        disabled={this.props.isDisabled}
                        onResolveSuggestions={this._onFilterChanged}
                        onChange={this.props._onChange}
                        itemLimit={this.state.currentPicker}
                        pickerSuggestionsProps={suggestionProps}
                        selectedItems={this.props.currentlySelectedItems}
                        key={'normal'}
                    />
                </TooltipHost>
            </React.Fragment>
        );
    }

    private _onFilterChanged = (
        filterText: string,
        currentPersonas: IPersonaProps[],
        limitResults?: number
    ): IPersonaProps[] | Promise<IPersonaProps[]> => {
        limitResults = 10;
        if (filterText.length > 3) {
            return new Promise((resolve) => {
                this.searchHelper(filterText.toLowerCase()).then((result: Array<IPersonaProps>) => {
                    const _result = limitResults ? result.splice(0, limitResults) : result;
                    resolve(_result)
                })
            });
        }
    };

    private searchHelper = (input: string): Promise<Array<IPersonaProps>> => {
        return new Promise((resolve, reject) => {
            api.getSearcheduser(input).then((result: IUser[]) => {
                var User: IPersonaProps[] = result['value'].map((data: IUser): IPersonaProps => {
                    const alias = this.isExternal(data.userPrincipalName);
                    return {
                        text: `${data.displayName}(${alias})`,
                        secondaryText: data.jobTitle ? data.jobTitle : data.mail,
                        className: 'is-width-300'
                    } as IPersonaProps;
                })
                resolve(User);
            });
        });
    }

    private isExternal = (userPrincipalName: string): string => {
        const isMicrosoft: boolean = (userPrincipalName.toLowerCase().indexOf('onmicrosoft.com') > 0)
        return isMicrosoft ? 'EXTERNAL' : userPrincipalName.substring(0, userPrincipalName.indexOf('@'))
    }
}
