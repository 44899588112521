import { FontIcon } from 'office-ui-fabric-react';
import * as React from 'react';

interface IAccessDeniedProps {
}

interface IAccessDeniedState {
    header: string;
    message: string;
}

export default class AccessDenied extends React.Component<IAccessDeniedProps, IAccessDeniedState> {
    constructor(props: IAccessDeniedProps) {
        super(props);

        this.state = {
            header: "Access Denied",
            message: "You do not have permissions to access this application. If you are a part of Devices organization and still cannot access the application, please email deviceshelp@microsoft.com to request access."
        }
    }

    public render() {
        return (
            <>
                <article className="message failed denied">
                    <div className="message-header failed denied">{this.state.header}</div>
                    <div className="message-body failed denied">{this.state.message}
                        <br />
                        <FontIcon iconName="EmojiDisappointed" className={"deniedIcon"} />
                    </div>
                </article>
            </>
        );
    }
}
