import { Label, TooltipHost } from 'office-ui-fabric-react';
import * as React from 'react';
import Dropzone from 'react-dropzone';

interface IDropZoneProps {
    _handleOnDrop: any;
    acceptedFiles: File[];
}

interface IDropZoneState {
    maxFileSize: number;
}

export default class DropZoneComponent extends React.Component<IDropZoneProps, IDropZoneState> {
    constructor(props: IDropZoneProps) {
        super(props);

        this.state = {
            maxFileSize: 1e+8, //100MB
        }
    }

    public render() {
        return (
            <div>
                <label htmlFor='dropZone' aria-label='click to browse files to upload'>
                    <TooltipHost
                        content="Please include .dsn file for Document requests."
                        calloutProps={{ gapSpace: 0 }}
                    >
                        <Dropzone onDrop={this.props._handleOnDrop} maxSize={this.state.maxFileSize} className='dropZone'>
                            <ul className="list-group">
                                <Label>Click to Browse / Drag and Drop</Label>
                                {this.props.acceptedFiles.length > 0 && this.props.acceptedFiles.map((acceptedFile, index) => (
                                    <li className="successFileList" key={index}>
                                        {acceptedFile.name}
                                    </li>
                                ))}
                            </ul>
                        </Dropzone>
                    </TooltipHost>
                </label>
            </div>
        );
    }
}
