import { OdinClient } from 'odinclient';
import { AttachmentDto, ErrorResponseDto } from 'odinclient/build/main/lib/generated';
import * as rp from 'request-promise-native';

import { ApiException } from '../models/ApiException';
import { IUser } from '../models/IUser';
import { adalGraphFetch, apiFetch, authContext } from './adalConfig';

const odinClient = new OdinClient(authContext.config.clientId, process.env.REACT_APP_ENV)

export interface IApiWrapperState {
    isLoading: boolean;
}
export class ApiWrapper {

    public logout = () => {
        localStorage.clear();
        authContext.logOut();
    }

    public getLoggedInUserOdin(): Promise<any> {
        return odinClient.graphExecuteQuery(`v1.0/users/${authContext.getCachedUser().userName}`, '2.0')
    }

    public getSearcheduser(user: string): Promise<any> {
        return odinClient.graphExecuteQuery(`v1.0/users/?$filter=(startswith(mail,'${user}')%20OR%20startswith(displayName,'${user}')%20OR%20startswith(userPrincipalName,'${user}'))&$top=50`, '2.0')

    }

    public getLoggedInUser(): Promise<IUser> {
        const options = {
            headers: {
                'Cache-Control': 'no-cache'
            },
            json: true,
        };
        const fetch = adalGraphFetch(
            rp,
            `https://graph.microsoft.com/v1.0/Me`,
            options
        )

        return fetch
    }

    public getUserPhoto(directoryId: string): Promise<any> {

        const options = {
            headers: {
                'Cache-Control': 'no-cache',
            },
            encoding: null
        };
        const fetch = adalGraphFetch(
            rp,
            `https://graph.microsoft.com/v1.0/users/${directoryId}/photos/48x48/$value`,
            options
        );

        return fetch;
    }

    public getAADUser(directoryId: string) {
        const fetch = adalGraphFetch(
            rp,
            `https://graph.microsoft.com/v1.0/users/${directoryId}`
        );
        debugger;
        return fetch;
    }

    public async uploadAttachmentsToAzureDevOps(files, api_version): Promise<AttachmentDto[]> {
        let url_ = this.getOdinEnvironment() + '/api/WorkItems/UploadAttachment?';
        if (api_version === undefined || api_version === null)
            throw new Error("The parameter 'api_version' must be defined and cannot be null.");
        else
            url_ += 'api-version=' + encodeURIComponent('' + api_version) + '&';
        url_ = url_.replace(/[?&]$/, '');
        const content_ = new FormData();
        if (files !== null && files !== undefined)
            for (let i = 0; i < files.length; i++) {
                content_.append('files', files[i], files[i].name);
            }
        let options_ = {
            body: content_,
            method: 'POST',
            headers: {
                Accept: 'application/json'
            }
        };
        const response = await apiFetch(url_, options_);
        return this.parseAttachmentResponse(response)
    }

    private async parseAttachmentResponse(response) {
        const status = response.status;
        let _headers = {};
        if (response.headers && response.headers.forEach) {
            response.headers.forEach((v, k) => (_headers[k] = v));
        }
        if (status === 400) {
            const _responseText = await response.text();
            let result400 = null;
            let resultData400 = _responseText === ''
                ? null
                : JSON.parse(_responseText);
            result400 = ErrorResponseDto.fromJS(resultData400);
            return this.throwException('Bad Request', status, _responseText, _headers, result400);
        }
        else if (status === 401 || status === 403) {
            const _responseText_1 = await response.text();
            let result401 = null;
            let resultData401 = _responseText_1 === ''
                ? null
                : JSON.parse(_responseText_1);
            result401 = ErrorResponseDto.fromJS(resultData401);
            return this.throwException('Unauthorized', status, _responseText_1, _headers, result401);
        }
        else if (status === 404) {
            const _responseText_2 = await response.text();
            let result404 = null;
            let resultData404 = _responseText_2 === ''
                ? null
                : JSON.parse(_responseText_2);
            result404 = ErrorResponseDto.fromJS(resultData404);
            return this.throwException('Not Found', status, _responseText_2, _headers, result404);
        }
        else if (status === 500) {
            const _responseText_3 = await response.text();
            let result500 = null;
            let resultData500 = _responseText_3 === ''
                ? null
                : JSON.parse(_responseText_3);
            result500 = ErrorResponseDto.fromJS(resultData500);
            return this.throwException('Server Error', status, _responseText_3, _headers, result500);
        }
        else if (status === 200) {
            const _responseText_4 = await response.text();
            let result200 = null;
            let resultData200 = _responseText_4 === ''
                ? null
                : JSON.parse(_responseText_4);
            if (Array.isArray(resultData200)) {
                result200 = [];
                for (let item of resultData200)
                    result200.push(AttachmentDto.fromJS(item));
            }
            return result200;
        }
        else if (status !== 200 && status !== 204) {
            const _responseText_5 = await response.text();
            return this.throwException('An unexpected server error occurred.', status, _responseText_5, _headers);
        }
        return Promise.resolve(null);
    }



    private getOdinEnvironment = () => {
        switch (process.env.REACT_APP_ENV.toLowerCase()) {
            case 'dev':
            case 'development':
            case 'local':
                return 'https://odindev.microsoft.com';
            case 'uat':
            case 'staging':
                return 'https://odinuat.microsoft.com';
            case 'prod':
            case 'production':
                return 'https://odin.microsoft.com';
            default:
                throw new Error('Invalid environment variable.');
        }
    }
    private throwException = (message, status, response, headers, result?) => {
        if (result !== null && result !== undefined)
            throw result;
        else
            throw new ApiException(message, status, response, headers, null);
    }
};
