import { runWithAdal } from 'react-adal';

import { authContext } from './components/utils/adalConfig';


// set to false after configuring environment variables and adalConfig.ts
const DO_NOT_LOGIN = false;

runWithAdal(
  authContext,
  () => {
    require('./appIndex/appIndex.tsx');
  },
  DO_NOT_LOGIN
);
