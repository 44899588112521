export interface IUser {
    displayName?: string;
    givenName: string;
    mail: string;
    surname: string,
    userPrincipalName: string;
    id: string;
    jobTitle?: string;
};

export interface IGroupPermission {
    groupId: number;
    isSecurityAdmin?: boolean;
    isProgramManager?: boolean;
    isEngagementPM?: boolean;
    isLeadership?: boolean;
    isGroupAdmin?: boolean;
};

export const EMPTY_USER: IUser = {
    displayName: '',
    givenName: '',
    surname: '',
    userPrincipalName: '',
    id: '',
    mail: '',
    jobTitle: ''
};
